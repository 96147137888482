import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

let userLang = localStorage.getItem('lang')
if(!userLang){
    userLang = navigator.language || navigator.userLanguage
    userLang = userLang.split('-')[0]
}
let locale = 'en'
if (['en', 'ru', 'vi'].indexOf(userLang) !== -1){
    locale = userLang
}

const i18n = new VueI18n({
    locale: locale,
    messages: {
        'en': require('@/i18n/languages/en.json'),
        'ru': require('@/i18n/languages/ru.json'),
        'vi': require('@/i18n/languages/vi.json'),
    }
})

export default i18n
