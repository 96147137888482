import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import axios from '@/modules/axios'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Blocked = () => import('@/views/pages/Blocked')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const Password = () => import('@/views/pages/Password')
const Calendar = () => import('@/views/calendars/Calendar')
const Categories = () => import('@/views/calendars/Categories')
const CalendarAdd = () => import('@/views/calendars/CalendarAdd')
const CalendarAddEvent = () => import('@/views/calendars/CalendarAddEvent')
const CalendarAddPage = () => import('@/views/calendars/CalendarAddPage')
const CalendarStatPage = () => import('@/views/calendars/CalendarStatPage')
const CalendarSign = () => import('@/views/calendars/CalendarSign')
const License = () => import('@/views/pages/License')
const Privacy = () => import('@/views/pages/Privacy')
const Unsubscribe = () => import('@/views/pages/Unsubscribe')
const SignSuccess = () => import('@/views/pages/SignSuccess')
const Profile = () => import('@/views/user/Profile')

const AdminUserList = () => import('@/views/admin/Users')

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/calendar',
      redirect: '/calendar/list',
      component: TheContainer,
      children: [
        {
          path: 'list',
          name: 'Dashboard',
          component: Dashboard,
        },
        {
          path: 'add-event/:cid/:copy_id',
          meta: { label: 'Добавление события'},
          name: 'CalendarAddEvent',
          component: CalendarAddEvent
        },
        {
          path: 'edit-event/:cid/:id',
          meta: { label: 'Редактирование события'},
          name: 'CalendarAddEvent',
          component: CalendarAddEvent
        },
        {
          path: 'add-page/:cid',
          meta: { label: 'Добавление подписной страницы'},
          name: 'CalendarAddPage',
          component: CalendarAddPage
        },
        {
          path: 'edit-page/:cid/:code',
          meta: { label: 'Редактирование подписной страницы'},
          name: 'CalendarAddPage',
          component: CalendarAddPage
        },
        {
          path: 'stat/:cid/:type/:page',
          meta: { label: 'Статистика'},
          name: 'CalendarStatPage',
          component: CalendarStatPage
        },
        {
          path: 'add',
          meta: { label: 'Добавление календаря'},
          name: 'CalendarAdd',
          component: CalendarAdd,
        },
        {
          path: 'edit/:id',
          meta: { label: 'Редактирование календаря'},
          name: 'CalendarAdd',
          component: CalendarAdd,
        },
        {
          path: 'categories/:id',
          meta: { label: 'Категории'},
          name: 'Categories',
          component: Categories,
        },
        {
          path: ':id',
          meta: { label: 'Просмотр календаря'},
          name: 'Calendar',
          component: Calendar,
        }
      ]
    },
    {
      path: '/license',
      name: 'License',
      component: License
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy
    },
    {
      path: '/signSuccess',
      name: 'SignSuccess',
      component: SignSuccess
    },
    {
      path: '/unsubscribe',
      name: 'Unsubscribe',
      component: Unsubscribe
    },
    {
      path: '/blocked',
      name: 'Blocked',
      component: Blocked
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/password',
      name: 'Password',
      component: Password
    },
    {
      path: '/user',
      redirect: '/calendar/list',
      component: TheContainer,
      children: [
        {
          path: 'profile',
          name: 'Profile',
          component: Profile,
        }
      ]
    },
    {
      path: '/admin',
      component: TheContainer,
      children: [
        {
          path: 'users',
          name: 'Users',
          component: AdminUserList,
        }
      ],
    },
    {
      path: '/404',
      name: 'Page404',
      component: Page404
    },
    {
      path: '/500',
      name: 'Page500',
      component: Page500
    },
    {
      path: '/*',
      name: 'CalendarSign',
      component: CalendarSign
    }
  ]
})

router.beforeEach((to, from, next) => {

  const authenticated = store.getters.authenticated,
      not_auth_routes = ['/login', '/register', '/password', '/blocked'],
      info_pages = ['/privacy', '/license', '/unsubscribe', '/']
  const path = to.path.toLowerCase()

  const interval = setInterval(() => {
    if(document.getElementsByClassName('b24-widget-button-wrapper').length){
      clearInterval(interval)
      if(info_pages.indexOf(path) === -1 && not_auth_routes.indexOf(path) === -1) {
        document.getElementsByClassName('b24-widget-button-wrapper')[0].style.display = 'block'
      } else {
        document.getElementsByClassName('b24-widget-button-wrapper')[0].style.display = 'none'
      }
    }
  }, 10)

  if(info_pages.indexOf(path) !== -1){
    return next();
  } else if(not_auth_routes.indexOf(path) === -1 && !authenticated && to.name !== 'CalendarSign') {// Если не авторизован - кидаем на авторизацию
    localStorage.setItem('requestedPath', to.fullPath)
    const jwt = localStorage.getItem('jwt')
    if(jwt){
      axios.post('/auth/login').then((res) => {
        const user = res.data.data;
        store.dispatch('setUser', user);
        localStorage.setItem('jwt', user.jwt)
        let route = '/'
        const requestedPath = localStorage.getItem('requestedPath')
        if(requestedPath) {
          route = requestedPath
          localStorage.removeItem('requestedPath')
        }
        return next(route)
      }).catch((error) => {
        localStorage.removeItem('jwt')
        console.log(error)
        return next('/login')
        // this.$notify({ group: 'auth', text: error.response.data.message, type: 'error' })
      })
    } else {
      return next('/login')
    }
  } else if(not_auth_routes.indexOf(path) !== -1 && authenticated) {// Если авторизован - кидаем на дашбоард
    return next('/');
  } else {
    return next();
  }
})

export default router
