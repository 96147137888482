const state = {
  user: null
}

const mutations = {
  setUser(state, user){
    state.user = user
  }
}

const actions = {

  setUser(context, user){
    context.commit('setUser', user);
  }

}

const getters = {
  user: state => {
    return state.user;
  },
  authenticated: state => {
    return !!state.user
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}