import axios from 'axios'
import config from "../config";
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import router from '../router'

const refreshAuthLogic = (failedRequest) => {
    let jwt = localStorage.getItem('jwt')
    if (jwt) {
        failedRequest.response.config.headers['jwt'] = jwt
    }

    return Promise.resolve()
}

createAuthRefreshInterceptor(axios, refreshAuthLogic, {})

// Настройки http-запросов
axios.defaults.baseURL = config.api_server
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Cache-Control'] = 'no-cache'

axios.interceptors.request.use(
    function (config) {
        if (localStorage.getItem('jwt')) {
            config.headers['jwt'] = localStorage.getItem('jwt')
        }

        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    (resp) => {
        const jwt = resp.headers.jwt
        if (jwt) {
            localStorage.setItem('jwt', jwt)
        }
        return resp
    },
    (error) => {
        if (error?.response?.status === 401) {
            this.$store.dispatch('setUser', null);
            localStorage.removeItem('jwt')
            router.push('/login')
        } else if (error?.response?.status === 423) {
            router.push('/blocked')
        }
        return Promise.reject(error)
    }
)

export default axios
